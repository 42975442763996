<template>
  <div class="container">
    <el-row :gutter="15">
      <el-col :span="12">
        <div class="input-wrapper">
          <p class="title">
            输入内容
            <span class="desc">点击获取示例，获得灵感</span>
            <span class="instance" @click="getInstance">获取示例</span>
          </p>
          <div class="input-controller">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="prompt"
              :maxlength="100000"
              :autosize="{ minRows:18, maxRows: 20 }"
              show-word-limit
              rows="4"
              clearable
              @change="clear"
            >
            </el-input>
            <el-input-number
              class="word_size"
              v-model="word_size"
              controls-position="right"
              :min="1"
              :max="10000"
              
              size="small"
            >
            </el-input-number>
            <el-button
              type="primary"
              class="submit-button"
              @click="write"
              :loading="writing"
              >{{ sub_button_text }}</el-button
            >
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="output-wrapper">
          <p class="title">
            输出内容 <span class="copy" @click="copyResult">复制结果</span>
            <span class="copy" @click="seeToken" style="margin-right: 5px"
              >显示token</span
            >
          </p>
          <div class="input-controller">
            <el-input
              type="textarea"
              placeholder="输出结果"
              v-model="result_text"
              :maxlength="100000"
              show-word-limit
              id="show-result"
              :autosize="{ minRows: 18, maxRows: 18 }"
            >
            </el-input>
          </div>
        </div>
      </el-col>
      <!-- <el-col :span="24">
        <img src="@/assets/imgs/wmp-ercode.png" class="qr_code" />
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
// import { mapActions, mapMutations, mapState } from "vuex";
import { getToken } from "@/utils/auth";

import { mapActions, mapState } from "vuex";
export default {
  name: "common",
  data() {
    return {
      prompt: "",
      request_id: "",
      word_size: 300,
      writing: false,
      status_code: null,
      result_text: "",
    };
  },
  computed: {
    ...mapState("completion", ["instance"]),
    sub_button_text() {
      return this.status_code == 206 ? "继续写作" : "立即生成";
    },

    completionRequestData() {
      let prompt =
        this.status_code == 206 ? this.prompt + this.result_text : this.prompt;
      return {
        //创建请求数据
        max_tokens: Math.ceil(this.word_size * 3.5),
        prompt: prompt,
        user_token: getToken(),
      };
    },
  },
  methods: {
    ...mapActions("completion", ["completion"]),
    clear() {
      this.result_text = "";
      this.request_id = "";
      this.writing = false;
      this.status_code = "";
    },
    write() {
      this.writing = true;
      let handle = this.completion({
        data: this.completionRequestData,
        onmessage: this.onmessage,
      });
      handle
        .then(({ code, data }) => {
          this.writing = false;
          this.status_code = code;
          
          console.log(code,data)
          if (code == 200) {
            this.$message.success("写作完成，记得保存呦！！");
          } else if(code == 50010){
            this.$router.push({'name':'PcRecharge'})
          } else {
            this.$message.info("还有内容，点击继续写作，获取后续内容！");
          }
          
        })
        .catch((err) => {
          console.log(err)
          this.writing = false;
        })
        
    },
    onmessage(resp) {
      this.result_text += resp;
      this.$nextTick(() => {
        const textarea = document.getElementById('show-result');
        textarea.scrollTop = textarea.scrollHeight;
      })
    },
    copyResult() {
      this.$copyText(this.result_text)
        .then((e) => {
          this.$message({
            type: "success",
            message: "复制成功",
          });
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: "浏览器不支持复制，请手动复制",
          });
        });
    },
    getInstance() {
      //获取列子
      let cmd = this.instance.shift();
      this.clear();
      this.prompt = cmd;
      this.instance.push(cmd);
    },
    seeToken() {
      this.result_text = getToken();
    },
  },
  mounted() {},
};
</script>

<style lang="stylus" scoped>
.container {
  display: block;
  box-sizing: border-box;
  width: 100%;
  .input-wrapper {
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 20px;
    .submit-button {
      margin-top: 20px;
      background-image: linear-gradient(90deg, #4a98ff, #2659ff);
      float: right;
    }

    .word_size {
      display: inline-block;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: 500;

      .desc {
        display: inline-block;
        margin-left: 10px;
        color: #8b8c95;
        font-size: 14px;
        font-weight: 400;
        vertical-align: bottom;
      }

      .instance {
        display: inline-block;
        float: right;
        color: #2932e1;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        vertical-align: top;
      }
    }
  }

  .output-wrapper {
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 24px;
    padding-bottom: 80px
    .title {
      font-size: 18px;
      font-weight: 500;

      .copy {
        display: inline-block;
        float: right;
        color: #2932e1;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        vertical-align: top;
      }
    }
  }
}
</style>